<template>
    <div>
        <div class="row section-header">
            <div class="col-md-5 section-title">
                <h3>Prenotazioni guide</h3>
            </div>
            <div class="col-md-4" style="padding-top: 6px">
                <b-form-select v-model="instructorId" @change="loadInstructorAvailabilities" v-if="hasRole('owner')">
                    <option value="0">- Visualizza tutte le prenotazioni -</option>
                    <optgroup label="Scegli un instruttore">
                        <option v-for="instructor in instructors" v-bind:value="instructor.id" :key="instructor.id">
                            {{ instructor.firstname }} {{ instructor.lastname }}
                        </option>
                    </optgroup>
                </b-form-select>
            </div>

            <div class="col-md-3 text-right" style="">
            </div>
        </div>

        <div class="top-content "></div>

        <p v-if="isMobile()">Per aggiungere una prenotazione, clicca sulla fascia oraria desiderata e trascina in basso per la durata necessaria.</p>

        <div class="content">
            <div class="index">
                <div class="card">
                    <FullCalendar ref="fullCalendar" class='demo-app-calendar' :options='calendarOptions'>
                        <template v-slot:eventContent='arg'>
                            <div v-if="arg.event.extendedProps.selezionato=='true'" class="text-center arrow-position">
                                <b-icon icon="arrow-down-circle-fill" animation="fade" font-scale="1.5"
                                        variant="danger"></b-icon>
                            </div>
                            <div>
                                <b>{{ arg.timeText }}</b>
                            </div>
                            <div>
                                <span style="font-size:10px;white-space: nowrap !important;">
                                    {{ arg.event.title }}
                                </span>
                            </div>
                        </template>
                    </FullCalendar>
                </div>
            </div>

            <div class="editor editor-small" :class="{ 'opened': (booking.id >= 0) }">
                <div class="top-editor">
                    <b-button variant="link" @click="closeEditor" :disabled="this.$store.state.loading">
                        <b-icon icon="x"/>
                        Chiudi
                    </b-button>
                </div>
                <div v-if="booking.id > 0">
                    <booking-edit-multiple :booking="booking" v-if="booking.vehicle.capacity > 0"
                                           :is-virtual="isVirtual"
                                           v-on:deleted-booking="deletedBooking()"
                                           v-on:removed-booking="removedBooking()"
                                           v-on:added-booking="addedBooking()"
                                           v-on:closed-editor="closeEditor()"/>

                    <booking-edit :booking="booking" v-else
                                  :is-virtual="isVirtual"
                                  v-on:deleted-booking="deletedBooking()"
                                  v-on:updated-booking="updatedBooking()"
                                  v-on:closed-editor="closeEditor()"/>
                </div>
                <div v-else-if="booking.id == 0">
                    <booking-create :booking="booking"
                                    :is-virtual="isVirtual"
                                    v-on:created-booking="createdBooking()"
                                    v-on:closed-editor="closeEditor()"/>
                </div>

            </div>

        </div>
        <div class="underlayer" v-if="booking.id > -1"></div>
    </div>

</template>

<script>
import InstructorAvailabilityDataService from "@/components/instructor_availability/InstructorAvailabilityDataService";
import StudentDataService from "@/components/student/StudentDataService";
import InstructorDataService from "@/components/instructor/InstructorDataService";
import BookingDataService from "@/components/booking/BookingDataService";
import BookingCreate from "@/components/booking/BookingCreate";
import BookingEdit from "@/components/booking/BookingEdit";
import BookingEditMultiple from "@/components/booking/BookingEditMultiple";
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import itLocale from '@fullcalendar/core/locales/it';
import {store} from "@/components/store";
import moment from 'moment';

export default {
    name: "booking-index",
    components: {BookingCreate, BookingEdit, BookingEditMultiple, FullCalendar},
    data() {
        return {
            booking: {
                id: -1,
                dateStart: '',
                dateEnd: '',
                instructorId: null,
                studentId: null,
                vehicleId: null,
            },
            isVirtual: false,
            can_edit_date: null,
            can_edit_limit_hours: 48,
            limit_seconds_disable_student: 600,
            instructors: [],
            instructorId: 0,
            calendarOptions: {
                plugins: [
                    dayGridPlugin,
                    timeGridPlugin,
                    interactionPlugin // needed for dateClick
                ],
                headerToolbar: {
                    left: 'prev,next today',
                    center: 'title',
                    right: '' //'dayGridMonth,timeGridWeek,timeGridDay'
                },
                slotMinTime: "08:00:00",
                slotMaxTime: "23:00:00",
                allDaySlot: false,
                contentHeight: window.innerHeight - 230,
                initialView: (this.isMobile()) ? 'timeGridDay' : 'timeGridWeek',
                nowIndicator: true,
                editable: true,
                selectable: true,
                selectMirror: true,
                dayMaxEvents: true,
                weekends: true,
                locale: itLocale,
                //timeZone: 'UTC',
                slotEventOverlap: true,
                events: this.retrieveInstructorAvailabilities,
                eventDrop: this.move_event,
                select: this.new_event,
                eventClick: this.edit_event,
                eventResize: this.resize_event

            }
        };
    },
    methods: {

        loadInstructorAvailabilities() {
            let calendarApi = this.$refs.fullCalendar.getApi();
            calendarApi.refetchEvents();
        },
        retrieveInstructorAvailabilities(fetchInfo, successCallback, failureCallback) {
            let events = [];
            if (this.instructorId == 0) {
                BookingDataService.getAllByDate(fetchInfo.startStr, fetchInfo.endStr).then(response_bookings => {
                    let id_ref = [];
                    let nextBooking = null
                    let addedStudents = 1;

                    for (let i = 0; i < response_bookings.data.length; i++) {
                        let item = response_bookings.data[i];
                        if (item.vehicle.isVirtual) continue;

                        if (i < response_bookings.data.length - 1) nextBooking = response_bookings.data[i+1];
                        else nextBooking = null;
                        let startDate = new Date(item.dateStart);
                        let endDate = new Date(item.dateEnd);
                        // compact multiple booking where dateStart, dateEnd, instructorId and vehicleId are the same
                        if (nextBooking && nextBooking.dateStart == item.dateStart && nextBooking.dateEnd == item.dateEnd && nextBooking.instructorId == item.instructorId && nextBooking.vehicleId == item.vehicleId) {
                            addedStudents++;
                        } else {
                            let event = {
                                id: item.id,
                                start: startDate,
                                end: endDate,
                                title: this.getEventTitle(item, addedStudents, item.vehicle.capacity),
                                backgroundColor: '#' + this.stringToColour(item.instructor.lastname+item.instructor.firstname) + 'aa',
                                type: 'booking',
                                selezionato: 'false',
                                student: item.student,
                                vehicle: item.vehicle,
                                editable: this.canEditEvent(item)

                            }
                            events.push(event);
                            addedStudents = 1;
                        }
                    }
                    successCallback(events);
                })
                    .catch(e => {
                        console.log(e);
                    });
            } else if (this.instructorId > 0) {
                InstructorAvailabilityDataService.getAllByInstructor(this.instructorId, fetchInfo.startStr).then(response_availabilities => {
                    BookingDataService.getAllByInstructor(this.instructorId).then(response_bookings => {

                        for (let item of response_availabilities.data) {
                            let startDate = new Date(item.dateStart);
                            let endDate = new Date(item.dateEnd);
                            let availability = {
                                groupId: "availableForBooking",
                                start: startDate,
                                end: endDate,
                                //title: 'ciccio',
                                backgroundColor: '#0069d9aa',
                                display: 'background',
                                type: 'availability',
                                selezionato: 'false',
                                vehicleId: item.vehicleId
                            }
                            events.push(availability);
                        }

                        let nextBooking = null
                        let addedStudents = 1;
                        for (let i = 0; i < response_bookings.data.length; i++) {
                            let item = response_bookings.data[i];
                            if (i < response_bookings.data.length - 1) nextBooking = response_bookings.data[i+1];
                            else nextBooking = null;
                            let startDate = new Date(item.dateStart);
                            let endDate = new Date(item.dateEnd);
                            // compact multiple booking where dateStart, dateEnd, instructorId and vehicleId are the same
                            if (nextBooking && nextBooking.dateStart == item.dateStart && nextBooking.dateEnd == item.dateEnd && nextBooking.instructorId == item.instructorId && nextBooking.vehicleId == item.vehicleId) {
                                addedStudents++;
                            } else {
                                let event = {
                                    id: item.id,
                                    start: startDate,
                                    end: endDate,
                                    title: this.getEventTitle(item, addedStudents, item.vehicle.capacity),
                                    //backgroundColor: '#ff0000aa',
                                    backgroundColor: '#'+this.stringToColour(item.instructor.lastname+item.instructor.firstname)+'aa',
                                    type: 'booking',
                                    editable: this.canEditEvent(item),
                                    selezionato: 'false',
                                    student: item.student,
                                    vehicle: item.vehicle,
                                    constraint: 'availableForBooking'
                                }
                                events.push(event);
                                addedStudents = 1;
                            }
                        }

                        successCallback(events);

                    })
                        .catch(e => {
                            console.log(e);
                        });
                })
                    .catch(e => {
                        console.log(e);
                    });
            } else {
                successCallback(events);
            }

        },
        getEventTitle(booking, addedStudents = 1, vehicleCapacity = 1) {
            //let title = '';
            if (vehicleCapacity > 1) return 'Posti prenotati: ' + addedStudents + '/' + vehicleCapacity + ' (' + booking.instructor.lastname + ')';
            else if (booking.student) return booking.student.firstname + ' ' + booking.student.lastname + ' (' + booking.instructor.lastname + ')';
            else return '(' + booking.instructor.lastname + ')';
            //return booking.instructor.firstname + ' ' + booking.instructor.lastname
        },

        canEditEvent(booking) {
            let start = new Date(booking.dateStart);
            if (['student'].every(r => this.$store.state.user.roles.indexOf(r) >= 0)) {
                if (booking.studentId == store.state.user.profileId) {
                    if (this.can_edit_date != null && start < this.can_edit_date) {
                        return false;
                    }
                } else {
                    return false;
                }
            } else if ((this.hasRole('student') || this.hasRole('instructor')) && this.can_edit_date != null && start < this.can_edit_date) {
                return false;
            }
            return true;
        },
        new_event(selectInfo) {
            let calendarApi = selectInfo.view.calendar
            calendarApi.unselect() // clear date selection

            this.booking.dateStart = selectInfo.startStr;
            this.booking.dateEnd = selectInfo.endStr;
            this.booking.instructorId = this.instructorId;
            this.booking.vehicleId = 0;
            this.booking.studentId = 0;
            this.booking.id = 0;

            const events = calendarApi.getEvents();
            if (events && events.length>0) {
                const availabilities = events.filter(event => event.extendedProps.type=='availability' && event.start<=selectInfo.start && event.end>=selectInfo.end);
                if (availabilities.length>0) {
                    this.booking.vehicleId = availabilities[0].extendedProps.vehicleId;
                }
            }
            //console.log(this.booking)

            calendarApi.addEvent({
                id: null,
                title: '',
                start: selectInfo.startStr,
                end: selectInfo.endStr,
                allDay: selectInfo.allDay,
                type: 'booking',
                backgroundColor: '#ff0000aa',
                selezionato: 'false'
            });
        },

        edit_event(clickInfo) {
            if (clickInfo.event.startEditable) {
                this.hide_arrow();
                BookingDataService.get(clickInfo.event.id).then(response => {
                    let book = response.data;
                    this.booking = {...book};
                    clickInfo.event.setExtendedProp("selezionato", "true");
                })
                    .catch(e => {
                        console.log(e);
                    });
            }
        },

        resize_event(clickInfo) {
            let payload = {
                dateStart: clickInfo.event.startStr,
                dateEnd: clickInfo.event.endStr
            }
            if (clickInfo.event.extendedProps.type == "booking") {
                let student = clickInfo.event.extendedProps.student;
                if (student != null && student.availableTime != null) {
                    if (clickInfo.oldEvent.end > clickInfo.event.end) {
                        BookingDataService.getAll().then(response_bookings => {
                            let db_bookings = response_bookings.data;
                            if (db_bookings != null && db_bookings.length > 0) {
                                let can_move = true;
                                const ds = new moment(payload.dateStart);
                                const de = new moment(payload.dateEnd);
                                const vehicle = clickInfo.event.extendedProps.vehicle;
                                for (let item of db_bookings) {
                                    if (new moment(item.dateStart).format('YYYY-MM-DD') == ds.format('YYYY-MM-DD') && item.vehicle.id == vehicle.id && item.vehicle.bookings != null && item.vehicle.bookings.length > 0) {
                                        for (let b of item.vehicle.bookings) {
                                            if (b.id != clickInfo.event.id) {
                                                can_move = new moment(b.dateEnd) <= ds || new moment(b.dateStart) >= de;
                                                if (!can_move) {
                                                    break;
                                                }
                                            }

                                        }
                                    }
                                    if (!can_move) {
                                        break;
                                    }
                                }
                                if (can_move) {
                                    BookingDataService.update(clickInfo.event.id, payload).then(response => {

                                    })
                                        .catch(e => {
                                            console.log(e);
                                        });
                                } else {
                                    alert("Il veicolo non è disponibile");
                                    clickInfo.revert();
                                }
                            }


                        })
                            .catch(e => {
                                console.log(e);
                            });

                    } else {
                        BookingDataService.getCountSecondsByStudentAndDate(student.id, moment().format('YYYY-MM-DD')).then(r => {
                            const student_hours = student.availableTime.secondsLeftPractice - r.data.seconds;
                            const x = new moment(payload.dateStart);
                            const y = new moment(payload.dateEnd);
                            const difference_date_booking = moment.duration(y.diff(x)).asSeconds();
                            if (student_hours > 0 && student_hours > difference_date_booking) {
                                BookingDataService.getAll().then(response_bookings => {
                                    let db_bookings = response_bookings.data;
                                    if (db_bookings != null && db_bookings.length > 0) {
                                        let can_move = true;
                                        const ds = new moment(payload.dateStart);
                                        const de = new moment(payload.dateEnd);
                                        const vehicle = clickInfo.event.extendedProps.vehicle;
                                        for (let item of db_bookings) {
                                            if (new moment(item.dateStart).format('YYYY-MM-DD') == ds.format('YYYY-MM-DD') && item.vehicle.id == vehicle.id && item.vehicle.bookings != null && item.vehicle.bookings.length > 0) {
                                                for (let b of item.vehicle.bookings) {
                                                    if (b.id != clickInfo.event.id) {
                                                        can_move = new moment(b.dateEnd) <= ds || new moment(b.dateStart) >= de;
                                                        if (!can_move) {
                                                            break;
                                                        }
                                                    }

                                                }
                                            }
                                            if (!can_move) {
                                                break;
                                            }
                                        }
                                        if (can_move) {
                                            BookingDataService.update(clickInfo.event.id, payload).then(response => {

                                            })
                                                .catch(e => {
                                                    console.log(e);
                                                });
                                        } else {
                                            alert("Il veicolo non è disponibile");
                                            clickInfo.revert();
                                        }
                                    }


                                })
                                    .catch(e => {
                                        console.log(e);
                                    });
                            } else {
                                alert("La prenotazione eccede le ore rimaste");
                                clickInfo.revert();
                            }
                        });
                    }

                } else {
                    clickInfo.revert();
                }

            }
        },

        move_event(clickInfo) {
            let payload = {
                dateStart: clickInfo.event.startStr,
                dateEnd: clickInfo.event.endStr
            }
            if (clickInfo.event.extendedProps.type == "booking") {
                BookingDataService.getAll().then(response_bookings => {
                    let db_bookings = response_bookings.data;
                    if (db_bookings != null && db_bookings.length > 0) {
                        let can_move = true;
                        const ds = new moment(payload.dateStart);
                        const de = new moment(payload.dateEnd);
                        const vehicle = clickInfo.event.extendedProps.vehicle;
                        for (let item of db_bookings) {
                            if (new moment(item.dateStart).format('YYYY-MM-DD') == ds.format('YYYY-MM-DD') && item.vehicle.id == vehicle.id && item.vehicle.bookings != null && item.vehicle.bookings.length > 0) {
                                for (let b of item.vehicle.bookings) {
                                    if (b.id != clickInfo.event.id) {
                                        can_move = new moment(b.dateEnd) <= ds || new moment(b.dateStart) >= de;
                                        if (!can_move) {
                                            break;
                                        }
                                    }

                                }
                            }
                            if (!can_move) {
                                break;
                            }
                        }
                        if (can_move) {
                            BookingDataService.update(clickInfo.event.id, payload).then(response => {

                            })
                                .catch(e => {
                                    console.log(e);
                                });
                        } else {
                            alert("Il veicolo non è disponibile");
                            clickInfo.revert();
                        }
                    }


                })
                    .catch(e => {
                        console.log(e);
                    });


            }
        },

        deletedBooking() {
            this.hide_arrow();
            this.loadInstructorAvailabilities()
            this.booking.id = -1;
        },

        removedBooking() {
            this.loadInstructorAvailabilities()
        },

        addedBooking() {
            this.loadInstructorAvailabilities()
        },

        updatedBooking() {
            this.hide_arrow();
            this.loadInstructorAvailabilities()
            this.booking.id = -1;
        },

        createdBooking() {
            let calendarApi = this.$refs.fullCalendar.getApi();
            let events = calendarApi.getEvents();
            let lastItem = events[events.length - 1];
            lastItem.remove();
            this.loadInstructorAvailabilities()
            this.booking.id = -1;
        },

        closeEditor() {
            this.hide_arrow();
            if (this.booking.id == 0) {
                let calendarApi = this.$refs.fullCalendar.getApi();
                let events = calendarApi.getEvents();
                let lastItem = events[events.length - 1];
                lastItem.remove();
            }
            this.booking.id = -1;
        },

        hide_arrow() {
            let calendarApi = this.$refs.fullCalendar.getApi();
            let events = calendarApi.getEvents();
            events.forEach(element => {
                element.setExtendedProp("selezionato", "false");
            });
        },

        updateCalendarSize() {
            let calendarApi = this.$refs.fullCalendar.getApi();
            setTimeout(() => {
                calendarApi.updateSize();
            }, 310);

        },
        resizeCalendareHeight() {
            let calendarApi = this.$refs.fullCalendar.getApi();
            calendarApi.setOption('height', window.innerHeight - 230 + 60);
            // is Mobile
            if (this.isMobile()) calendarApi.changeView('timeGridDay');
            else calendarApi.changeView('timeGridWeek');
        },
        isMobile() {
            return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) || window.innerWidth <= 650;
        },
        stringToColour(str) {
            let hash = 0;
            for (let i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash);
            }
            let colour = '';
            for (let i = 0; i < 3; i++) {
                let value = (hash >> (i * 8)) & 0xFF;
                colour += ('00' + value.toString(16)).substr(-2);
            }
            return colour;
        }

    },
    created() {
        document.addEventListener('toggleSidebar', this.updateCalendarSize);
        window.addEventListener("resize", this.resizeCalendareHeight);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.resizeCalendareHeight);
        window.removeEventListener("toggleSidebar", this.updateCalendarSize);
    },
    mounted() {
        this.can_edit_date = new Date();
        this.can_edit_date.setHours(this.can_edit_date.getHours() + this.can_edit_limit_hours);
        if (this.hasRole('instructor') && !this.hasRole('owner')) {
            this.instructorId = this.$store.state.user.profileId;
            this.loadInstructorAvailabilities();
        }
        InstructorDataService.getAll()
            .then(response => {
                this.instructors = response.data;
            })
            .catch(e => {
                console.log(e);
            });
        document.addEventListener('toggleSidebar', function (e) { /* ... */
        }, false);
    },
    watch: {
        'booking.id': function () {
            this.updateCalendarSize();
        },

    }
};
</script>

<style scoped>

.underlayer {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    z-index: 50;
}

.vuecal__event {
    width: 96% !important;
    left: 2% !important;
    border: 0px solid transparent;
    margin: 0px auto;
    box-sizing: border-box;

    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;

}

.vuecal__event.lunch {
    background: repeating-linear-gradient(45deg, transparent, transparent 10px, #f2f2f2 10px, #f2f2f2 20px);
}

.blu {

    background-color: rgba(0, 105, 217, .2);
    color: #000;
    font-size: 13px;
}

.vuecal__event.blu.vuecal__event--focus {
    background-color: #0069d9;
    color: #fff;
}

.grey {
    background-color: #7e7e7e;
    color: #fff;
    font-size: 13px;
}

.arrow-position {
    position: absolute;
    top: -23px;
    width: 100%;
}

.vuecal__view-btn {
    font-size: 1em;
}

.vuecal__title-bar {
    font-size: 1.1em;
    padding-top: 10px;
    padding-bottom: 10px;
}

@media screen and (min-width: 651px) {
    .editor-small.opened {
        top: 31vh;
    }

    .editor-small {
        max-width: 380px;
    }

}

</style>


<template>
    <div>
        <div v-if="booking" class="card">

            <h5>{{ formatDate(booking.dateStart) }}</h5>
            <b-form>

                Studenti
                <table class="table table-sm">
                    <tbody>
                    <tr v-for="(addedStudent, index) in addedBookings" :key="index">
                        <td>{{ addedStudent.student.firstname}} {{ addedStudent.student.lastname}}</td>
                        <td style="width: 50px"><a href="#" class="text-danger" @click="deleteBooking(addedStudent.id)"><b-icon-trash/></a></td>
                    </tr>
                    </tbody>
                </table>

                <div v-if="showAddStudentButton && addedBookings.length < booking.vehicle.capacity">
                    <b-button @click="addStudent" variant="primary" size="sm"><b-icon-plus/> Aggiunge studente</b-button>
                </div>
                <div v-if="!showAddStudentButton && addedBookings.length < booking.vehicle.capacity">
                    <b-form-group id="studentId" label="" label-for="studentId" description="">
                        <b-form-select v-model="booking.studentId">
                            <option value="0">- Scegli uno studente -</option>
                            <option v-for="student in notBookedStudents" v-bind:value="student.id" :key="student.id" :disabled="checkStudentDisabled(student)">
                                {{ student.firstname }} {{ student.lastname }} ({{ convertSecondsToHour(student) }}/h)
                            </option>
                        </b-form-select>
                    </b-form-group>
                    <b-button @click="addBooking" variant="success" size="sm">Aggiungi</b-button>
                    <br>
                </div>
                <br>

                <b-form-group id="instructorId" label="Istruttore" label-for="instructorId" description="" v-if="!isVirtual">
                    <b-form-select v-model="booking.instructorId" :state="validateState('instructorId')" :disabled="disable_instructors">
                        <option value="0">- Scegli un istruttore -</option>
                        <option v-for="instructor in instructors" v-bind:value="instructor.id" :key="instructor.id">
                            {{ instructor.firstname }} {{ instructor.lastname }}
                        </option>
                    </b-form-select>
                </b-form-group>

                <b-form-group id="vehicleId" label="Mezzo" label-for="vehicleId" description="">
                    <b-form-select v-model="booking.vehicleId" :state="validateState('vehicleId')" disabled>
                        <option value="0">- Scegli un veicolo -</option>
                        <template v-for="vehicle in vehicles">
                            <option v-if="checkVehicleEnabled(vehicle)"  v-bind:value="vehicle.id" :key="vehicle.id">
                                {{ vehicle.name }}
                            </option>
                            <option v-else v-bind:value="vehicle.id" :key="vehicle.id" disabled>
                                {{ vehicle.name }} (Non disponibile)
                            </option>
                        </template>
                    </b-form-select>

                </b-form-group>
            </b-form>

            <br>
            <b-button variant="outline-danger" @click="deleteBooking(0)" :disabled="this.$store.state.loading">
                <b-icon icon="trash"/> Rimuovi tutta la prenotazione in blocco
            </b-button>
            <div>
                <br>
                <b-alert variant="success" :show="message != ''" fade>
                    <b-icon icon="hand-thumbs-up"/>
                    {{ message }}
                </b-alert>
                <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
            </div>
        </div>

        <div v-else>
            <br/>
            <p>{{ (errorMsg) ? errorMsg : 'Please click on a Booking edit...' }}</p>
        </div>
    </div>
</template>

<script>
import BookingDataService from "./BookingDataService";
import {required} from 'vuelidate/lib/validators'
import InstructorDataService from "@/components/instructor/InstructorDataService";
import VehicleDataService from "@/components/vehicle/VehicleDataService";
import StudentDataService from "@/components/student/StudentDataService";
import moment from 'moment';
import {store} from "@/components/store";

export default {
    name: "booking-edit",
    data() {
        return {
            disable_instructors: false,
            limit_seconds_disable_student: 600,
            vehicles: [],
            students: [],
            addedBookings: [],
            addedStudentIds: [],
            showAddStudentButton: true,
            instructors: [],
            student: null,
            message: '',
            errorMsg: ''
        };
    },
    validations() {
        return {
            booking: this.rules
        }
    },
    props: ["booking","isVirtual","studentId"],
    methods: {
        retriveMultipleBookings() {
            BookingDataService.getAllByDate(this.booking.dateStart, this.booking.dateEnd)
                .then(response => {
                    this.addedBookings = response.data.filter(book => (book.instructorId == this.booking.instructorId &&  book.vehicleId == this.booking.vehicleId));
                    this.addedBookings.forEach(booking => {
                        this.addedStudentIds.push(booking.studentId)
                    });
                })
                .catch(e => {
                    console.log(e);
                });
        },
        checkVehicleEnabled(vehicle) {
            let enabled = true;
            if (vehicle.bookings!=null && vehicle.bookings.length>0) {
                let ds = new moment(this.booking.dateStart);
                let de = new moment(this.booking.dateEnd);
                enabled = vehicle.bookings.every(b => new moment(b.dateEnd)<=ds || new moment(b.dateStart)>=de);
            }
            if (vehicle.unavailabilityDateStart != null) {
                let ds = new moment(this.booking.dateStart);
                let de = new moment(this.booking.dateEnd);
                if (vehicle.unavailabilityDateEnd == null) enabled = ds.isBefore(vehicle.unavailabilityDateStart);
                else enabled = ds.isBefore(vehicle.unavailabilityDateStart, 'days') || ds.isAfter(vehicle.unavailabilityDateEnd, 'days');
            }
            return enabled;
        },
        checkStudentDisabled(student) {
            let disabled = true;
            if (student.availableTime!=null) {
                if (this.isVirtual) disabled = student.availableTime.secondsLeftSimulator < this.limit_seconds_disable_student || student.availableTime.secondsLeftSimulator < this.difference_date_booking;
                else disabled = student.availableTime.secondsLeftPractice < this.limit_seconds_disable_student || student.availableTime.secondsLeftPractice < this.difference_date_booking;
            }
            return disabled;
        },
        removeBookedStudentFromList() {
            let addedStudentIds = [];
            this.addedBookings.forEach(booking => {
                addedStudentIds.push(booking.id);
            });
            for (let i = this.students.length; i > 0; i--) {
                let student = this.students[i];
                if (addedStudentIds.includes(student.id)) {
                    this.students.splice(i,1);
                }
            }
        },
        convertSecondsToHour(student) {
            let hours = 0;
            if (student.availableTime!=null) {
                if (this.isVirtual) hours = moment.duration(student.availableTime.secondsLeftSimulator, 'seconds').asHours().toFixed(2);
                else hours = moment.duration(student.availableTime.secondsLeftPractice, 'seconds').asHours().toFixed(2);
            }
            return hours;
        },

        validateState(name) {
            const {$dirty, $error} = this.$v.booking[name];
            return $dirty ? !$error : null;
        },
        addStudent() {
            this.showAddStudentButton = false;
        },
        addBooking() {
            BookingDataService.create(this.booking)
                .then(response => {
                    this.retriveMultipleBookings();
                    this.$emit('added-booking');
                    this.showAddStudentButton = true;
                    this.booking.studentId = 0;
                })
                .catch(e => {
                    this.errorMsg = 'Non hai compilato tutti i campi';
                    console.log(e);
                });
        },

        deleteBooking(id) {
            if (id > 0) {
                if (confirm("Vuoi rimuovere lo studente dalla prenotazione?")) {
                    BookingDataService.delete(id)
                        .then(response => {
                            this.retriveMultipleBookings();
                            this.$emit('removed-booking');
                        })
                        .catch(e => {
                            console.log(e);
                        });
                }
            } else {
                if (confirm("Vuoi cancellare tutta la prenotazione?")) {
                    for (let i = 0; i < this.addedBookings.length; i++) {
                        BookingDataService.delete(this.addedBookings[i].id)
                            .then(response => {
                                this.$emit('deleted-booking');
                            })
                            .catch(e => {
                                console.log(e);
                            });
                    }

                }
            }
        },

        closeEditor() {
            this.booking.instructorId=0;
            this.booking.vehicleId=0;
            this.booking.studentId=0;
            this.$emit('closed-editor');
        },
        formatDate(datetime) {
            moment.locale('it');
            let formattedDate = moment(datetime).format('LLLL');
            return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
        },
        getStudents(id) {
             if (id!=null && id>0) {
                 StudentDataService.get(id)
                    .then(response => {
                        this.students = new Array(response.data);
                        if (this.students.length>0) {
                            this.students.forEach(student => {
                                const startDate = moment().format('YYYY-MM-DD');
                                if (this.isVirtual) {
                                    if (student.availableTime != null && student.availableTime.secondsLeftSimulator > 0) {
                                        BookingDataService.getCountSecondsByStudentAndDate(student.id, startDate, true).then(r => {
                                            student.availableTime.secondsLeftSimulator -= r.data.seconds;
                                        });
                                    }
                                }
                                else {
                                    if (student.availableTime != null && student.availableTime.secondsLeftPractice > 0) {
                                        BookingDataService.getCountSecondsByStudentAndDate(student.id, startDate, false).then(r => {
                                            student.availableTime.secondsLeftPractice -= r.data.seconds;
                                        });
                                    }
                                }
                                
                            });
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    });
            } else {
                StudentDataService.getAll()
                    .then(response => {
                        this.students = response.data;
                        if (this.students.length>0) {
                            this.students.forEach(student => {
                                const startDate = moment().format('YYYY-MM-DD');
                                if (this.isVirtual) {
                                    if (student.availableTime != null && student.availableTime.secondsLeftSimulator > 0) {
                                        BookingDataService.getCountSecondsByStudentAndDate(student.id, startDate, true).then(r => {
                                            student.availableTime.secondsLeftSimulator -= r.data.seconds;
                                        });
                                    }
                                } else {
                                    if (student.availableTime != null && student.availableTime.secondsLeftPractice > 0) {
                                        BookingDataService.getCountSecondsByStudentAndDate(student.id, startDate, false).then(r => {
                                            //console.log(r.data.seconds);
                                            student.availableTime.secondsLeftPractice -= r.data.seconds;
                                        });
                                    }
                                }
                                
                            });
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }
        }
        
    },
    computed: {
        rules () {
            return this.isVirtual
                ? { studentId: { required }, vehicleId: { required } }
                : { studentId: { required }, vehicleId: { required }, instructorId: { required } }
        },
        notBookedStudents() {
            return this.students.filter(s => !this.addedStudentIds.includes(s.id));
        }
    },
    mounted() {
        this.message = '';
        this.disable_instructors=true;
        if (this.studentId > 0) {
            StudentDataService.get(this.studentId)
                .then(response => {
                    this.student = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        } else {
            // recupero tutte le prenotazione che hanno la stessa data, istruttore e veicolo
            this.retriveMultipleBookings();
            this.booking.studentId = 0;
        }

        InstructorDataService.getAll()
            .then(response => {
                this.instructors = response.data;
            })
            .catch(e => {
                console.log(e);
            });

        VehicleDataService.getAll()
            .then(response => {
                if (this.isVirtual) this.vehicles = response.data.filter(v => v.isVirtual);
                else this.vehicles = response.data.filter(v => !v.isVirtual);
            })
            .catch(e => {
                console.log(e);
            });
        if (store.state.user.roles.length==1 && store.state.user.roles[0]=='student') {
            this.getStudents(store.state.user.profileId);
        } else {
            this.getStudents();
        }
       
    }
};
</script>

<style scoped>
.table tr td {
    padding: 0.25rem
}
.action-bar {
    margin-top: 15px;
}
</style>
